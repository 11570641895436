.cardType3 {
  /* padding-bottom: 15px; */
  margin-bottom: 15px;
  font-size: 10px;
  min-height: 100px;
}

.title {
  font-size: 20px !important;
  font-weight: 600;
  color: #005293;
  margin-bottom: 5px;
}

.title1 {
  font-size: 16px !important;
  font-weight: 500;
}

.description {
  /* width: 90%; */
  font-weight: 500;
  font-size: 15px !important;
  line-height: 2em;
}

.description3 {
  font-weight: 500;
  font-size: 1.3em;
  line-height: 1.6em;
}
.description3:hover{
  color: #005293 !important;
}

.description1 {
  font-family: inherit;
  font-weight: 600;
  font-size: 1.55em;
  line-height: 1.3em;
}

.image {
  transform: scale(1.02);
  transition: all 0.75s;
  /* max-height: 220px !important; */
  object-fit: cover;
}

.image:hover {
  transform: scale(1.2);
}

.tag {
  position: absolute;
  color: #fff;
  background-color: #005293;
  font-size: 20px !important;
  font-weight: 500;
  padding: 0 25px;
  border-bottom-right-radius: 5px;
  top: -2.5px;
  left: 6px;
  z-index: 100;
}

.dateWrapper {
  text-align: right;
}

.date {
  background-color: #e6f0f9;
  font-weight: 500;
}

button {
  cursor: pointer;
  font-size: 1em;
  padding: 2.5px 10px;
  background-color: #fff;
  border: 1px solid black;
  width: 100px;
}
.subscribeStyle {
  width: 6em !important;
  height: 6em !important;
  border-radius: 50% !important;
  object-fit: cover;
}
.subscribeCol {
  display: block;
  margin: auto;
}

@media only screen and (max-width: 1400px) {


  .description {
    /* font-size: 19px !important; */
    line-height: 1.8em;
  }

  .description1 {
    font-size: 1.5vw;
  }

  button {
    font-size: 1.2vw;
    padding: 2.5px 9px;
    width: 100px;
  }

  .title1 {
    font-size: 15px !important;
    line-height: 1em;
  }
}

@media only screen and (max-width: 1200px) {
  .title {
    /* font-size: 22px !important; */
  }

  .description {
    /* font-size: 18px !important; */
    line-height: 1.6em;
  }

  .tag {
    font-size: 17px !important;
    font-weight: 500;
    padding: 0 15px;
  }
  .description1 {
    font-size: 1.5vw;
  }

  button {
    font-size: 1.2vw;
    padding: 2.5px 9px;
    width: 100px;
  }

  .title1 {
    font-size: 14px !important;
    line-height: 1em;
  }

  .date {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1120px) {
  .title {
    font-size: 21px !important;
  }

  .description {
    font-size: 19px !important;
    line-height: 1.55em;
  }

  .tag {
    font-size: 17px !important;
    font-weight: 500;
    padding: 0 15px;
  }

  .description1 {
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 21px !important;
  }

  .description {
    font-size: 17px !important;
    line-height: 1.55em;
  }

  .tag {
    font-size: 17px !important;
    font-weight: 500;
    padding: 0 15px;
  }

  .title1 {
    font-size: 20px !important;
    font-weight: 500;
  }

  .description1 {
    font-size: 1.5vw;
  }

  .date {
    font-size: 18px !important;
  }
  .image{
    /* max-height:180px !important ; */
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 18px !important;
    line-height: 1.47em;
  }

  .description {
    font-size: 18px !important;
    line-height: 1.47em;
  }

  .tag {
    font-size: 14px !important;
    font-weight: 500;
    padding: 0 10px;
  }

  .description1 {
    font-size: 2.7vw;
  }

  button {
    font-size: 1.6vw;
    padding: 2.5px 9px;
    width: 100px;
  }
  .image{
    /* max-height: 160px !important; */
  }
}

@media only screen and (max-width: 680px) {
  .title {
    font-size: 19px !important;
    line-height: 1.3em;
  }

  .description {
    font-size: 17px !important;
    line-height: 1.55em;
  }

  .tag {
    font-size: 12px !important;
    font-weight: 500;
    padding: 0 10px;
  }

  .description1 {
    font-size: 2.7vw;
  }

  button {
    font-size: 1.6vw;
    padding: 2.5px 9px;
    width: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .title {
    font-size: 21px !important;
  }

  .description {
    font-size: 16px !important;
    line-height: 1.55em;
  }

  .tag {
    font-size: 17px !important;
    font-weight: 500;
    padding: 0 15px;
  }

  .title1 {
    font-size: 18px !important;
  }

  .description1 {
    font-size: 1.2em;
  }

  .date {
    font-size: 16px !important;
  }

  button {
    font-size: 0.9em;
    padding: 2.5px 9px;
    width: 100px;
  }
}

@media only screen and (max-width: 425px) {
  .title1 {
    font-size: 16.5px !important;
  }

  .date {
    font-size: 15px !important;
  }
}
