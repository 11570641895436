::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}
.magazine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.magazine:hover {
  opacity: 1;
}
.btn {
  width: fit-content;
  color: white !important;
  border: 1px solid white !important;
  background-color: rgba(255, 255, 255, 0.085) !important;
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper-container {
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 37rem;
  position: relative;
}

.textStyle {
  font-size: 65px !important;
  font-optical-sizing: auto;
  font-weight: 800 !important;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 1400px) {
  .swiper-container {
    padding: 2rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 32rem;
    position: relative;
  }

  .textStyle {
    font-size: 60px !important;
  }
}

@media (max-width: 1300px) {
  .swiper-container {
    padding: 2rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 32rem;
    position: relative;
  }

  .textStyle {
    font-size: 60px !important;
  }
}

@media (max-width: 1200px) {
  .swiper-container {
    padding: 2rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 31rem;
    position: relative;
  }
}

@media (max-width: 1100px) {
  .swiper-container {
    padding: 2rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 31rem;
    position: relative;
  }
}

@media (max-width: 992px) {
  .swiper-slide {
    width: 30rem;
    position: relative;
  }

  .textStyle {
    font-size: 58px !important;
  }
}

@media (max-width: 895px) {
  .swiper-container {
    padding-top: 5px;
  }

  .textStyle {
    font-size: 55px !important;
    margin-top: 5px;
  }
}

@media (max-width: 815px) {
  .swiper-container {
    padding-top: 5px;
  }

  .swiper-slide {
    width: 28rem;
    position: relative;
  }

  .textStyle {
    font-size: 55px !important;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 22rem !important;
  }

  .swiper-container {
    padding-top: 5px;
  }

  .swiper-slide {
    width: 28rem;
    position: relative;
  }
  .textStyle {
    font-size: 53px !important;
  }
}

@media (max-width: 660px) {
  .textStyle {
    font-size: 45px !important;
  }
}

@media (max-width: 576px) {
  .swiper-slide {
    width: 19rem !important;
  }
  .swiper-slide img {
    width: 19rem !important;
  }

  .textStyle {
    font-size: 40px !important;
  }
}

@media (max-width: 480px) {
  .textStyle {
    font-size: 35px !important;
  }
}

.swiper-slide img {
  width: 37rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
