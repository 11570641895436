.dailyScoopTitleWrapper {
  font-size: 10px;
  border-bottom: 2.3px solid #152966;
}

.dailyScoopText {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 3.2em !important;
  font-weight: 700 !important;
}

.dailyScoopTitle {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 5.6em !important;
  font-weight: 700 !important;
}

.yearButtonsWrapper {
  margin-top: 35px;
  width: 100%;
}

.year {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.year .yearButtonSelect {
  color: #fff;
  background-color: #00569e;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 800;
  padding: 8px 70px;
}

.year .yearNormal {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 800;
  color: gray;
  padding: 8px 70px;
}

@media only screen and (max-width: 1200px) {
  .year .yearButtonSelect {
    font-size: 25px;
    font-weight: 800;
    padding: 8px 60px;
  }

  .year .yearNormal {
    font-size: 25px;
    padding: 8px 60px;
  }
}

@media only screen and (max-width: 992px) {
  .year .yearButtonSelect {
    font-size: 24px;
    font-weight: 800;
    padding: 8px 50px;
  }

  .year .yearNormal {
    font-size: 24px;
    padding: 8px 50px;
  }
}

@media only screen and (max-width: 768px) {
  .year .yearButtonSelect {
    font-size: 24px;
    font-weight: 800;
    padding: 8px 50px;
    margin: 0;
  }

  .year .yearNormal {
    font-size: 24px;
    padding: 8px 50px;
    margin: 0;
  }
}

@media only screen and (max-width: 576px) {
  .dailyScoopTitle {
    width: 100%;
    font-size: 4.5em !important;
  }

  .dailyScoopText {
    font-size: 2.8em !important;
  }

  .year .yearButtonSelect {
    font-size: 22px;
    font-weight: 800;
    padding: 8px 45px;
    margin: 0;
  }

  .year .yearNormal {
    font-size: 22px;
    padding: 8px 45px;
    margin: 0;
  }
}

@media only screen and (max-width: 425px) {
  .dailyScoopTitle {
    width: 100%;
    font-size: 4.5em !important;
  }

  .dailyScoopText {
    font-size: 2.8em !important;
  }

  .year .yearButtonSelect {
    font-size: 20px;
    font-weight: 800;
    padding: 8px 35px;
    margin: 0;
  }

  .year .yearNormal {
    font-size: 20px;
    padding: 8px 35px;
    margin: 0;
  }
}
