.logoWrapper {
  height: 6.8em;
  width: 14em;
  background-color: #fff;
  padding: 0 1.5em;
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
}

.LogoAlignment {
  width: auto;
  /* margin-top: 0.5em; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoImage {
  object-fit: cover;
  height: 100%;
}
.visionarylogo {
  height: auto;
  width: 362px;
  object-fit: cover;
  margin-left: 10rem;
  display: flex;
  align-items: center;
}

.logoTexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  height: 4.8em;
}

.logoText1,
.logoText2 {
  display: block !important;
  font-weight: 700 !important;
}

.logoText1 {
  border-bottom: 1px solid rgb(216, 210, 210);
}

.logoImage {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 710px) {
  .logoWrapper {
    margin-right: 1.5em;
  }
  .visionarylogo {
    height: auto;
    width: 261px;
    object-fit: cover;
    margin-left: 2rem;
    display: flex;
    align-items: center;
  }

  /* .LogoAlignment {
    width: 7em;
  } */
}
@media only screen and (max-width: 375px) {
  .visionarylogo {
    height: auto;
    width: 200px;
    object-fit: cover;
    margin-left: 2rem;
    display: flex;
    align-items: center;
  }
}
