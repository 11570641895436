.cardType1 {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  padding-bottom: 0.7em;
  margin-bottom: 1.2em;
  cursor: pointer;
  /* margin: 5px; */
}

.magazineCard {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  padding-bottom: 0.7em;
  margin: 15px;
  cursor: pointer;
}

.cardType1Image {
  margin-bottom: 0.5em;
  object-fit: cover;
  /* width: 100%;
  height: auto; */
}

.width2 {
  width: 240px;
}

.dateTag {
  position: absolute;
  color: #000;
  background-color: #FFFFFF;
  opacity: 0.9;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2em !important;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 5px;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.textWrapper {
  padding: 5px 13px;
  min-height:110px;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 17px !important;
  line-height: 1.3em;
  text-align: center;
}

.title:hover, .link:hover {
  color: #005293 !important;
}

.title2 {
  color: #fff !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 2.4em;
  line-height: 1.3em;
}

.title2 .link:hover {
  text-decoration: underline !important;
}

.description {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  font-size: 2em !important;
  line-height: 1.4em;
}

.magazineTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.5em !important;
  line-height: 1.3em;
  cursor: pointer;
}

.magazineTitle:hover {
  color: red !important;
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.btnUpTrending {
  background-color: #00569e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50% !important;
}

.btn {
  cursor: pointer;
  font-size: 1em;
  padding: 2.5px 10px;
  background-color: #fff;
  border: 1px solid black;
  width: 100px;
}
.titleHover:hover {
  color: red !important;
}

.btn2 {
  margin-top: 0.5em;
  cursor: pointer;
  font-size: 1.55em;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid black;
  width: 100px;
}
.eachmagazineTitle {
  font-weight: 700;
  font-size: 1.5em !important;
}
.eachmagazineTitle:hover {
  color: red !important;
}

@media only screen and (max-width: 1200px) {
  .tag {
    font-size: 1.2em !important;
  }

  .title {
    font-size: 1.7em !important;
  }

  .description {
    font-size: 1.7em !important;
  }
}

@media only screen and (max-width: 992px) {
  .btn2 {
    font-size: 1.4em;
  }

  .title {
    font-size: 1.6em !important;
  }
  .textWrapper{
    min-height: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .tag {
    font-size: 1.1em !important;
  }

  .title {
    font-size: 1.em !important;
  }

  .magazineTitle {
    font-size: 1.3em !important;
  }

  .description {
    font-size: 1.55em !important;
  }

  .btn {
    font-size: 1.6vw;
    padding: 2.5px 9px;
    width: 100px;
  }

  .btn2 {
    margin-top: 0.5em;
    cursor: pointer;
    font-size: 1.55em;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid black;
    width: 100px;
  }

}

@media only screen and (max-width: 576px) {
  .tag {
    font-size: 1em !important;
  }

  .title {
    font-size: 1.6em !important;
  }

  .description {
    font-size: 1.4em !important;
  }
  .textWrapper{
    min-height: auto;
  }
}

@media only screen and (max-width: 425px) {
  .tag {
    font-size: 0.85em !important;
  }

  .title {
    font-size: 1.25em !important;
  }

  .description {
    font-size: 1em !important;
  }
}
