
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
/* .ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
} */


.ql-editor {
  /* height: 100%;
  flex: 1; */
  min-height: 150px;
  overflow-y: scroll;
  resize: vertical;
  /* width: 100%; */
}

.ql-container.ql-snow {
  border: none;
  min-height: 150px;
  overflow: scroll;
}
