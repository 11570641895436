section {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(12, 12, 12, 0.85);

  /* center overlay text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  font-size: 24px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 500;
  color: #fff;
  text-align: center;
  width: 75%;
}

.title {
  width: 50% !important;
  color: #fff !important;
  text-align: center;
  font-size: 40px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.text {
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-size: 1.05em !important;
  font-weight: 500;
  line-height: 1.5em;
}

.buttonWrapper {
  margin-top: 53px;
  text-align: center;
}

.buttonWrapper button {
  width: 275px;
  background-color: #00569e;
  color: #fff;
  padding: 15px 5px;
  font-size: 24px;
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 500;
  border: none;
  border: 3px solid #e4e4e4;
  border-radius: 50px;
}

.formSection {
  padding: 25px 100px;
}

.formSection_textWrapper {
  text-align: center;
  margin-bottom: 35px;
}

.formSection_text {
  font-size: 16px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 500;
  line-height: 1.7em;
}

.formSection_textBold {
  text-transform: uppercase;
  font-size: 32px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 700;
  line-height: 1.6em;
}

.formInput {
  text-align: left;
  padding: 8px 15px;
  border: none;
  border: 3px solid #00569e;
}

.formInputCheckbox {
  text-align: left;
  font-size: 16px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 500;
}

input[type="file"] {
  font-size: 16px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 500;
}

.formInput::placeholder {
  font-size: 16px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 500;
  color: #6e6e6e;
  margin-left: 10px;
}

.sponsoredPostTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.divider {
  height: 3px;
  width: 180px !important;
  align-self: flex-start;
  margin-left: 40px;
  margin: 20px 0;
  background-color: #000;
}

.sponsoredTitle {
  font-size: 40px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 600 !important;
  line-height: 1em !important;
  width: 90%;
  margin-right: 10px;
  margin-left: 0;
  text-align: left;
}

.sponsoredText {
  width: 90%;
  font-size: 20px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  color: rgba(22, 21, 21, 0.7);
  font-weight: 600;
  line-height: 1.6em;
  text-align: left;
}
.sponsoredTitle{
  margin-top: 12px !important;
  color: white !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: left !important;
  font-size: 2.2em !important;
}
.formInput {
  padding: 9px 12px;
  text-align: left;
  margin-top: 10px !important;
  background-color: #ebebeb;
}
.formInput::placeholder {
  color: grey;
  font-weight: 500;
}
.textStyle {
  padding: 70px 0px;
  background-image: url("../../Assets/sponseredbanner.png") !important;
  background-size: cover;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 37px !important;
  }

  .overlay .text {
    font-size: 22px !important;
    line-height: 1.5em;
  }

  .buttonWrapper button {
    width: 255px;
    padding: 15px 5px;
    font-size: 22px;
  }

  .formSection_text {
    font-size: 15px !important;
    line-height: 1.7em;
  }

  .formSection_textBold {
    font-size: 30px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 36px !important;
  }

  .sponsoredText {
    font-size: 18px !important;
    line-height: 1.6em;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 36px !important;
    width: 65% !important;
  }

  .overlay .text {
    width: 90% !important;
    font-size: 21px !important;
    line-height: 1.5em;
  }

  .buttonWrapper {
    margin-top: 30px;
  }

  .buttonWrapper button {
    width: 250px;
    padding: 13px 5px;
    font-size: 21px;
  }

  .listingRow {
    padding: 35px;
  }

  .formSection_text {
    font-size: 15px !important;
    line-height: 1.7em;
  }

  .formSection_textBold {
    font-size: 29px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 36px !important;
    width: 100%;
    text-align: center;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 18px !important;
    line-height: 1.6em;
    text-align: center;
  }

  .divider {
    display: none;
  }
}

@media screen and (max-width: 830px) {
  .title {
    font-size: 35px !important;
  }

  .overlay .text {
    font-size: 20px !important;
    line-height: 1.4em;
  }

  .buttonWrapper {
    margin-top: 28px;
  }

  .buttonWrapper button {
    width: 218px;
    padding: 11px 5px;
    font-size: 19px;
  }

  .formSection {
    padding: 25px 75px;
  }

  .formSection_text {
    font-size: 15px !important;
    line-height: 1.55em;
  }

  .formSection_textBold {
    font-size: 26px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 35px !important;
    width: 100%;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 17px !important;
    line-height: 1.6em;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 30px !important;
  }

  .overlay .text {
    font-size: 17px !important;
    line-height: 1.4em;
  }

  .buttonWrapper {
    margin-top: 10px;
  }

  .buttonWrapper button {
    width: 210px;
    padding: 11px 5px;
    font-size: 18px;
  }

  .listingRow {
    padding: 35px;
  }

  .formSection {
    padding: 25px 65px;
  }

  .formSection_text {
    font-size: 16px !important;
    line-height: 1.55em;
  }

  .formSection_textBold {
    font-size: 25px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 33px !important;
    width: 100%;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 16px !important;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 650px) {
  .title {
    font-size: 25px !important;
  }

  .overlay .text {
    font-size: 14px !important;
    line-height: 1.4em;
  }

  .buttonWrapper {
    margin-top: 12px;
  }

  .buttonWrapper button {
    width: 165px;
    padding: 9px 5px;
    font-size: 14px;
  }

  .listingRow {
    padding: 30px;
  }

  .formSection {
    padding: 25px 15px;
  }

  .formSection_text {
    font-size: 15px !important;
    line-height: 1.55em;
  }

  .formSection_textBold {
    font-size: 24px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 28px !important;
    width: 100%;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 16px !important;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px !important;
    margin-top: 10px;
  }

  .overlay .text {
    font-size: 13px !important;
    line-height: 1.4em;
  }

  .buttonWrapper {
    margin-top: 10px;
  }

  .buttonWrapper button {
    width: 150px;
    padding: 9px 5px;
    font-size: 13px;
  }

  .listingRow {
    padding: 23px;
  }

  .formSection {
    padding: 25px 15px;
  }

  .formSection_text {
    font-size: 14.5px !important;
    line-height: 1.55em;
  }

  .formSection_textBold {
    font-size: 20px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 26px !important;
    width: 100%;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 15px !important;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 495px) {
  .title {
    font-size: 22px !important;
    margin-bottom: 3px !important;
  }

  .overlay .text {
    font-size: 11px !important;
    line-height: 1.33em;
  }

  .buttonWrapper {
    margin-top: 8px;
  }

  .buttonWrapper button {
    width: 135px;
    padding: 6px 5px;
    font-size: 11px;
  }

  .formSection {
    padding: 25px 12px;
  }

  .formInput::placeholder {
    font-size: 14px !important;
  }

  .formInputCheckbox {
    font-size: 14px !important;
  }

  .formSection_text {
    font-size: 14px !important;
    line-height: 1.55em;
  }

  .formSection_textBold {
    font-size: 19px !important;
    line-height: 1.6em;
  }

  .sponsoredTitle {
    font-size: 25px !important;
    width: 100%;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 14px !important;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 405px) {
  .title {
    font-size: 18px !important;
    margin-bottom: 3px !important;
  }

  .overlay .text {
    font-size: 10px !important;
    line-height: 1.33em;
  }

  .buttonWrapper {
    margin-top: 8px;
  }

  .buttonWrapper button {
    border: 2px solid #fff;
    width: 115px;
    padding: 5px 5px;
    font-size: 10px;
  }

  .sponsoredTitle {
    font-size: 23px !important;
    width: 100%;
    line-height: 1.1em !important;
  }

  .sponsoredText {
    margin: 0 auto;
    font-size: 13px !important;
    line-height: 1.5em;
  }
}
