.dailyScoopContent {
  font-size: 10px;
  padding: 25px 0 0 0;
}

.titleWrapper {
  width: 100%;
  margin-left: 36px;
}

.title {
  /* font-family: "Montserrat", sans-serif; */
  color: #fff !important;
  font-optical-sizing: auto;
  text-align: left;
  font-size: 3.5em !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  width: 100%;
}

.divider {
  background-color: #fff;
  width: 352px !important;
  height: 7px;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .title{
    font-size: 3em !important;
  }
}
