.cardType1 {
  font-size: 10px;
  padding-bottom: 0.7em;
  margin-bottom: 1.2em;
  cursor: pointer;
  margin: 8px;
}

.magazineCard {
  font-size: 10px;
  padding-bottom: 0.7em;
  margin: 15px;
  cursor: pointer;
}

.magazin{
  margin-bottom: 0.5em;
  background-size: cover;
  object-fit: cover;
  width: 100% !important;
  height: 100%;
}

.cardType1Image {
  margin-bottom: 0.5em;
  background-size: cover !important;
  object-fit: cover !important;
}
.magazinheight{
  height: 450px !important;
}

.width2 {
  width: 240px;
}

.tag {
  position: absolute;
  color: #fff;
  background-color: #005293;
  font-size: 1.3em !important;
  font-weight: 500;
  padding: 2px 12px;
  border-bottom-right-radius: 5px;
  top: 0;
  left: 0px;
  z-index: 100;
}

.title {
  font-weight: 500;
}

.title .link {
  font-size: 17px !important;
  line-height: 1.3em;
  display: block !important;
  width: 95% !important;
  text-align: center;
}

.title .link:hover {
  color: #005293 !important;
}

.title2 {
  text-align: center;
  font-weight: 500;
  font-size: 17px !important;
  line-height: 1.3em;
  color: white !important;
}


.description {
  font-family: inherit;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.6em;
}

.magazineTitle {
  font-weight: 600;
  font-size: 1.8em !important;
  text-align: center;
  line-height: 1.3em;
  cursor: pointer;
  margin-top: 15px;
}

.magazineTitle:hover {
  color:#005293 !important ;
}
.btn {
  cursor: pointer;
  font-size: 1em;
  padding: 2.5px 10px;
  background-color: #fff;
  border: 1px solid black;
  width: 100px;
}
.titleHover:hover {
  color: red !important;
}

.btn2 {
  margin-top: 0.5em;
  cursor: pointer;
  font-size: 1.55em;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid black;
  width: 100px;
}
.eachmagazineTitle {
  font-weight: 700;
  font-size: 1.5em !important;
}
.eachmagazineTitle:hover {
  color: red !important;
}

@media only screen and (max-width: 1400px){
  .magazinheight{
    height: 380px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .tag {
    font-size: 1.2em !important;
  }

  .title .link .title2 {
    font-size: 1.3em !important;
  }

  .description {
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 992px) {
  .btn2 {
    font-size: 1.4em;
  }

  .title .link .title2{
    font-size: 1.2em !important;
  }
  .magazinheight{
    height: 350px !important;
  }
}

@media only screen and (max-width: 768px) {
  .tag {
    font-size: 1.1em !important;
  }

  .title .link .title2{
    font-size: 1.2em !important;
  }
  .magazinheight{
    height: 390px !important;
  }

  .magazineTitle {
    font-size: 1.3em !important;
  }

  .description {
    font-size: 1.3em;
  }

  .btn {
    font-size: 1.6vw;
    padding: 2.5px 9px;
    width: 100px;
  }

  .btn2 {
    margin-top: 0.5em;
    cursor: pointer;
    font-size: 1.55em;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid black;
    width: 100px;
  }
}
@media screen and (max-width: 600px) {
  .magazinheight{
    height: 300px !important;
  }
}

@media only screen and (max-width: 576px) {
  .tag {
    font-size: 1em !important;
  }

  .title .link .title2{
    font-size: 1.2em !important;
  }

  .description {
    font-size: 1.3em;
  }

  .btn {
    font-size: 0.9em;
    padding: 2.5px 9px;
    width: 100px;
  }
  .cardType1Image{
    width: 100%;
  }
  .magazinheight{
    height: auto!important;
  }
}

@media only screen and (max-width: 425px) {
  .tag {
    font-size: 0.85em !important;
  }

  .title .link .title2{
    font-size: 1.05em !important;
  }
}
