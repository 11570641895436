.content {
    margin-top: 1px;
    /* font-family: "Poppins", sans-serif; */
    font-size: 10px;
  }
  
  .rowContent {
    background-color: black;
    margin-top: 1.5px;
    margin-bottom: 10px;
    padding: 25px;
    padding-top: 35px;
  }
  
  .textWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .authorName {
    color: #fff !important;
    text-align: left;
    /* font-family: "Poppins", sans-serif !important; */
    font-size: 6em !important;
    font-weight: 500 !important;
    line-height: 1em !important;
    width: 100% !important;
  }
  
  .title {
    font-size: 2.1em !important;
    font-weight: 400 !important;
    width: 100% !important;
    font-optical-sizing: auto;
    line-height: 1.2em;
    word-spacing: 5px !important;
  }
  
  .buttonWrapper button {
    width: 200px;
    background-color: #00569e;
    color: #fff;
    padding: 15px 5px;
    font-size: 16px;
    /* font-family: "Poppins", sans-serif; */
    font-weight: 500;
    border: none;
    border: 3px solid #e4e4e4;
    border-radius: 50px;
  }
  
  .posterContainer {
    border: 1px solid black;
  }
  
  .button {
    width: 180px;
    align-self: flex-start;
    /* font-family: "Poppins", sans-serif; */
    margin-top: 0;
    margin-left: 13px;
    border-radius: 3px;
    padding: 12px 10px;
    font-size: 15px;
    font-weight: 500;
  }
  
  .descImg img {
    width: 100%;
  }

  .descImg iframe[src*="youtube"] {
    aspect-ratio: 16 / 9;
    /* Overrides inline width and height attrs in the default embed code */
    width: 100% !important;
    height: auto !important;
  }
  
  
  .descImg {
    /* font-family: "Poppins", sans-serif; */
    font-size: 17px;
    padding: 15px;
  }
  .descImg h2 {
    text-align: center;
    padding: 0px 30px !important;
  }
  
  .buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .imageContainer {
    width: 100%;
    max-width: 350px;
    height: auto;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .imageContainer .image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  @media only screen and (max-width: 1220px) {
    .authorName {
      font-size: 5em !important;
      line-height: 1em !important;
    }
  
    .title {
      font-size: 2em !important;
    }
  
    .button {
      width: 165px;
      font-size: 14px;
    }
    .buttonWrapper button {
      width: 190px;
      padding: 12px 5px;
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width: 1025px) {
    .authorName {
      font-size: 3.8em !important;
      line-height: 1em !important;
    }
  
    .title {
      font-size: 1.8em !important;
    }
  
    .button {
      width: 165px;
      font-size: 13px;
    }
  
    .descImg h2 {
      font-size: 12px !important;
    }
  
    .buttonWrapper button {
      width: 180px;
      padding: 12px 5px;
      font-size: 15px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .authorName {
      font-size: 3.6em !important;
      line-height: 1em !important;
    }
  
    .title {
      font-size: 1.5em !important;
    }
  
    .button {
      padding: 10px;
      width: 165px;
      font-size: 13px;
    }
  
    .descImg h2 {
      font-size: 12px !important;
    }
  
    .buttonWrapper button {
      width: 170px;
      padding: 12px 5px;
      font-size: 14px;
    }
  
    .imageContainer {
      max-width: 100%;
    }
  }
  
  @media only screen and (max-width: 685px) {
    .authorName {
      font-size: 3.4em !important;
      line-height: 1em !important;
    }
  
    .title {
      font-size: 1.4em !important;
    }
  
    .button {
      padding: 8px;
      width: 140px;
      font-size: 12px;
    }
  
    .descImg h2 {
      font-size: 12px !important;
    }
  }
  