.magazinesTitle {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  text-align: left;
  font-size: 3em !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

