.container {
  margin: 20px auto;
  padding: 10px;
  width: 550px;
  height: 550px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 110px;
}

h1 {
  width: 70%;
  color: #777;
  font-size: 36px;
  margin: 28px auto;
  margin-bottom: 20px;
  text-align: center;
  /*padding-top: 40px;*/
}

form {
  /*padding: 15px;*/
  text-align: center;
  position: relative;
}

input {
  padding: 12px 0;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 2px solid transparent;
  text-align: center;
  width: 90%;
  font-size: 20px;
  transition: border 0.2s, background-color 0.2s;
}

form .field {
  background-color: #ecf0f1;
}

form .field:focus {
  border: 2px solid #3498db;
}

form .btn {
  background-color: #000;
  color: #fff;
  line-height: 25px;
  cursor: pointer;
  transition: all 0.3s;
}

form .btn:hover,
form .btn:active {
  background-color: #e53f23;
  border: 2px solid #e53f23;
}

.icon {
  cursor: pointer;
  font-size: 21px;
  position: absolute;
  top: 70px;
  right: 40px;
  color: #282828;
}

.icon_visible {
  color: #e53f23;
}

.pass_link {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pass_link button {
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 15px;
  border: none;
}

.pass_link .icon {
  font-size: 18px !important;
}
