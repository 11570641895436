.dailyScoopTitleWrapper {
  font-size: 10px;
  border-bottom: 2.3px solid #152966;
}

.dailyScoopTitle {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 5.6em !important;
  font-weight: 700 !important;
}

@media only screen and (max-width: 576px) {
  .dailyScoopTitle {
    width: 100%;
    font-size: 4em !important;
  }
}
