.text {
  display: block;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  color: white !important;
  width: 80%;
}
.maincontent {
  margin-top: 2.5em !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #00569e;
  padding: 50px 0px 60px;
}
.imageStyle {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url("../../Assets/beyond.png");
}
.textStyle {
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.8);
  color: white !important;
  font-size: 24px;
  width: 100%;
  height: 100%;
  padding: 50px 0px 80px;
}
.beyondtext {
  font-weight: 400;
  font-size: 22px;
  color: white;
  display: block;
}
.li {
  font-size: 22px;
  line-height: 1.6em;
}
.whowearetext {
  font-weight: 500;
  font-size: 22px;
  display: block;
}
.participation {
  background-color: #00569e;
  padding: 10px;
}
.participationtext {
  width: 95%;
  padding: 2rem 3rem 0px 3rem;
  font-weight: 500;
  font-size: 22px;
  display: block;
}
.participationli {
  font-size: 22px;
  line-height: 1.8em;
  font-weight: 500;
  width: 95%;
}
@media screen and (max-width: 1200px) {
  .whowearetext {
    font-weight: 500;
    font-size: 20px;
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .participationli,
  .participationtext,
  .whowearetext,
  .beyondtext,
  .li,
  .text {
    font-size: 17px;
  }
}
