.image {
  background-image: url("../../Assets/MockUP.jpg");
  /* background-position: center left; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 150px 150px 100px;
}
.smalltitle {
  letter-spacing: 10px;
  color: white;
  /* font-family: "Heebo", sans-serif; */
  text-transform: uppercase;
}
.largeTitle {
  margin: 20px 0;
  font-weight: 400;
  font-size: 50px;
  display: block;
  color: white;

  /* font-family: "Playfair Display", sans-serif; */
  text-transform: uppercase;
  line-height: 62px;
}
.paragraph {
  /* font-family: "Poppins", sans-serif; */
  font-size: 16px;
  color: white;
  font-weight: 200;
  letter-spacing: 0.1px;
  width: 520px;
}
.btn {
  background-color: white;
  color: black;
  /* font-family: "Roboto", sans-serif; */
  width: 200px;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-radius: 5px;
}
@media screen and (max-width: 1200px) {
  .image {
    padding: 50px;
  }
  .paragraph {
    width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  .image {
    padding: 50px;
  }
}
@media screen and (max-width: 768px) {
  .image {
    background-image: none;
    background-color: #095191;
    padding: 100px 20px;
    text-align: center;
  }
  .paragraph {
    width: auto;
  }
}
