.footerContent {
  font-size: 10px;
  /* background-color: rgba(21, 41, 102, 1); */
  background-color: #000;
  padding: 0;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px !important;
  font-weight: 600;
  text-align: left !important;
  color: #fff;
}

.description {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
  text-align: left !important;
  color: #fff;
  line-height: 1.3em;
}

.icon {
  font-size: 20px !important;
}

.socialMediaIcons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}

.listingText {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.listingText a {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
}

.copyright {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
}
