section {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(12, 12, 12, 0.85);

  /* center overlay text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  font-size: 32px !important;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500;
  color: #fff;
  text-align: center;
  width: 75%;
}

.title {
  text-transform: uppercase;
  font-size: 40px !important;
  color: #fff !important;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 700 !important;
  margin-bottom: 0;
}

.text {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-size: 1.05em !important;
  font-weight: 500;
  line-height: 1.5em;
}

.buttonWrapper {
  margin-top: 53px;
}

.buttonWrapper button {
  width: 250px;
  background-color: #00569e;
  color: #fff;
  padding: 15px 5px;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border: none;
  border: 3px solid #e4e4e4;
  border-radius: 50px;
}

.listingRow {
  padding: 30px;
}

.divider {
  background-color: #000;
  margin: 14px !important;
}

.viewMagazineBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.viewMagazineBtnWrapper button {
  width: 120px;
  background-color: #00569e;
  color: #fff;
  padding: 11px 5px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border: none;
  border: 3px solid #e4e4e4;
  border-radius: 50px;
}

.sideSection {
  padding-top: 10px;
  padding-right: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 37px !important;
  }

  .overlay .text {
    font-size: 29px !important;
    line-height: 1.5em;
  }

  .buttonWrapper button {
    width: 230px;
    padding: 15px 5px;
    font-size: 22px;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 36px !important;
  }

  .overlay .text {
    font-size: 26px !important;
    line-height: 1.5em;
  }

  .buttonWrapper button {
    width: 228px;
    padding: 15px 5px;
    font-size: 21px;
  }
}

@media screen and (max-width: 830px) {
  .title {
    font-size: 36px !important;
  }

  .overlay .text {
    font-size: 26px !important;
    line-height: 1.5em;
  }

  .buttonWrapper {
    margin-top: 53px;
  }

  .buttonWrapper button {
    width: 220px;
    padding: 15px 5px;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 34px !important;
  }

  .overlay .text {
    font-size: 22px !important;
    line-height: 1.5em;
  }

  .buttonWrapper {
    margin-top: 20px;
  }

  .buttonWrapper button {
    width: 200px;
    padding: 13px 5px;
    font-size: 18px;
  }
}

@media screen and (max-width: 650px) {
  .title {
    font-size: 33px !important;
  }

  .overlay .text {
    font-size: 20px !important;
    line-height: 1.5em;
  }

  .buttonWrapper {
    margin-top: 12px;
  }

  .buttonWrapper button {
    width: 155px;
    padding: 11px 5px;
    font-size: 15px;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 27px !important;
  }

  .overlay .text {
    font-size: 15px !important;
    line-height: 1.4em;
  }

  .buttonWrapper {
    margin-top: 12px;
  }

  .buttonWrapper button {
    width: 145px;
    padding: 9px 5px;
    font-size: 13px;
  }
}

@media screen and (max-width: 425px) {
  .title {
    font-size: 23px !important;
  }

  .overlay .text {
    font-size: 12px !important;
    line-height: 1.4em;
  }

  .buttonWrapper {
    margin-top: 8px;
  }

  .buttonWrapper button {
    width: 125px;
    padding: 6px 5px;
    font-size: 11px;
  }
}
