.titleWrapper {
  width: 100%;
  margin-left: 36px;
}

.title {
  font-optical-sizing: auto;
  text-align: left;
  font-size: 40px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  width: 100%;
  color: #fff !important;
}

.divider {
  background-color: #fff;
  width: 350px !important;
  height: 7px;
  margin: 0;
}
.texttitle {
  font-optical-sizing: auto;
  font-size: 1.6em !important;
  font-weight: 600;
  margin: 1em 0;
  color: #fff !important;
}
.textdesc {
  font-size: 1rem;
  color: #fff !important;
}
.submitbtn {
  height: 70px;
  background-color: #053c7c;
  color: #fff;
  font-size: 25px;
  width: 220px;
  border: 1px solid black;
  border-radius: 50px;
  font-weight: 700;
}
.subscribe {
  margin-top: 400px;
  transform: rotate(-4deg);
}
.righttext {
  margin: 50px 0px !important;
  display: block;
  font-weight: 500;
  font-size: 1.4em;
}

.imageOneCss {
  transform: rotate(10deg);
  position: absolute;
  right: -35%;
}

.imageTwoCss {
  transform: rotate(-10deg);
  position: absolute;
  top: 190px;
  right: 0%;
}

.imageThreeCss {
  transform: rotate(-5deg);
  position: relative;
  top: 360px;
  right: -50%;
}
@media screen and (max-width: 1100px) {
  .imageOneCss {
    transform: rotate(10deg);
    position: absolute;
    right: -35%;
  }

  .imageTwoCss {
    transform: rotate(-10deg);
    position: absolute;
    top: 130px;
    right: 0%;
  }

  .imageThreeCss {
    transform: rotate(-5deg);
    position: relative;
    top: 250px;
    right: -50%;
  }
  .subscribe {
    margin-top: 270px;
  }
}
@media screen and (max-width: 992px) {
  .imageOneCss {
    transform: rotate(10deg);
    position: absolute;
    right: 100px;
  }

  .imageTwoCss {
    transform: rotate(-20deg);
    position: absolute;
    top: 140px;
    left: 50px;
  }

  .imageThreeCss {
    transform: rotate(-10deg);
    position: relative;
    top: 245px;
    left: 160px;
  }
  .righttext {
    font-size: 17px;
  }
  .subscribe {
    margin-top: 280px;
  }
}
@media screen and (max-width: 768px) {
  .imageOneCss {
    transform: rotate(10deg);
    position: absolute;
    right: 0%;
  }

  .imageTwoCss {
    transform: rotate(-20deg);
    position: absolute;
    top: 140px;
    left: 50px;
  }

  .imageThreeCss {
    transform: rotate(-10deg);
    position: relative;
    top: 245px;
    left: 40%;
  }
}
@media screen and (max-width: 576px) {
  .imageOneCss {
    width: 160px!important;
    transform: rotate(10deg);
    position: absolute;
    right: 10%;
  }

  .imageTwoCss {
    width: 160px!important;
    transform: rotate(-20deg);
    position: absolute;
    top: 100px;
    left: 50px;
  }

  .imageThreeCss {
    width: 160px!important;
    transform: rotate(-10deg);
    position: relative;
    top: 180px;
    left: 25%;
  }
  .subscribe {
    margin-top: 220px;
  }
}
