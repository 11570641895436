.title {
  font-size: 1.8em !important;
  font-weight: 800;
}
.book {
  width: 800px;
}
.leftBtn,
.rightBtn {
  border: none;
  background: none;
  width: auto !important;
  color: black !important;
}
.leftBtn:hover,
.rightBtn:hover {
  background-color: beige !important;
  width: 20px;
  height: 180px;
}
.fullScreen {
  width: 100%;
  height: 100%;
}
.enterIcon {
  background: none;
  border: none;
  font-size: 2em;
  width: fit-content;
}
.page {
  background: none;
  border: none;
  font-size: 1.5em;
  width: fit-content;
  color: black;
}
@media screen and (max-width: 992px) {
  .book {
    width: 570px;
  }
}
@media screen and (max-width: 576px) {
  .book {
    width: 300px;
  }
  .leftBtn:active,
  .rightBtn:active {
    background-color: none !important;
    width: 20px;
    height: 180px;
    color: black;
  }
  .leftBtn,
  .rightBtn {
    color: black !important;
    font-size: 7px !important;
  }
}
