.dailyScoopContent {
  font-size: 10px;
  padding: 25px 0;
}

.titleWrapper {
  width: 100%;
  margin-left: 36px;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  text-align: left;
  font-size: 40px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  width: 100%;
}

.divider {
  background-color: #000;
  width: 325px !important;
  height: 7px;
  margin: 0;
}
