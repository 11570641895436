.hellotitle {
  font-size: clamp(20px, 4vw, 32px);
  color: white;
  font-weight: 300 !important;
}
.contactUsTitle {
  margin-top: 12px !important;
  color: white !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: left !important;
}
.normaltext {
  font-size: large;
  color: white;
}
.text {
  font-size: large;
  color: white;
  font-weight: 300;
}
.smallTitle,
.messageTitle {
  color: white !important;
  font-weight: bold !important;
  text-align: left !important;
  width: 100% !important;
}
.smallTitle {
  font-size: 1.6em !important;
}
.messageTitle {
  font-size: 2em !important;
}
.icontext {
  display: flex;
  align-items: flex-start;
  padding: 8px 0px;
  font-size: 20px;
  color: white;
}
.textStyle {
  padding: 70px 0px;
  background-image: url("../../Assets/contact.png") !important;
  background-size: cover;
}
.formInput {
  border-radius: 30px;
  padding: 9px 12px;
  text-align: left;
  margin-top: 10px !important;
  background-color: #ebebeb;
}
.formInput::placeholder {
  color: grey;
  font-weight: 500;
  padding-left: 10px;
}
.sendBtn {
  display: inline-flex;
  padding: 28px 45px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: fit-content;
  background: #005293;
  border-radius: 30px !important;
  color: #fff;
  font-size: 20px;
}
@media screen and (max-width: 1440px) {
  .text {
    font-size: 17px !important;
  }
}