.descImg img {
  width: 100%;
}
.descImg {
  /* font-family: "Poppins", sans-serif; */
  font-size: 20px;
  padding: 15px;
}

.descImg iframe[src*="youtube"] {
  aspect-ratio: 16 / 9;
  /* Overrides inline width and height attrs in the default embed code */
  width: 100% !important;
  height: auto !important;
}

.descImg h2 {
  text-align: center;
  padding: 0px 30px !important;
}
.descContent {
  box-shadow: 0px 0px 5px grey;
  margin-right: 20px;
}
.dailyScoopTitleWrapper {
  font-size: 10px;
  border-bottom: 2.3px solid #152966;
}
.dailyScoopText {
  text-transform: uppercase;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 3.2em !important;
  font-weight: 700 !important;
}

.dailyScoopTitle {
  text-transform: uppercase;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 5.6em !important;
  font-weight: 700 !important;
}
.socialaccounts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}
.sharePost {
  font-weight: 500;
  font-size: 18px;
  margin-top: 10px;
}
.viewallbtn {
  display: flex;
  justify-content: center;
}
.btn {
  border: none;
  background: #005293;
  color: white;
  font-size: 20px;
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 5px grey;
  margin-bottom: 50px;
  /* font-family: "Poppins", sans-serif; */
}
@media only screen and (max-width: 992px) {
  .descContent {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .dailyScoopTitle {
    width: 100%;
    font-size: 4.5em !important;
  }

  .dailyScoopText {
    font-size: 2.8em !important;
  }
  .descImg {
    font-size: 16px;
  }
  .sharePost {
    font-size: 14px;
  }
  .descImg h2 {
    text-align: center;
    padding: 0px 10px !important;
  }
}
