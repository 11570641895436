.category{
    color: red;
    font-size: 1.1em;
}
.container{
    text-align: center;
}
.title{
    font-size: 2em!important;
    font-weight: 800;
}
.accounts{
    width: 100px;
    border: 1px solid black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35px;
    margin: 20px auto;
}
.allAccounts{
    display: flex;
    justify-content: center;
    align-items: center;
}
.account{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.description{
    font-family: 'Roboto', sans-serif;
    margin-bottom: 20px!important;
}
.title1{
    font-weight: 500;
    font-size: 1.5em!important;
    margin: 0;
    text-align: left;
}
.heading{
    font-weight: 500;
    font-size: 1.4em!important;
    text-align: left!important;
    margin: 0;
}
.stickyCol{
    position: sticky !important;
    top: 0;
    height: max-content;
}
@media screen and (max-width:992px) {
    .stickyCol{
        position: static;
    }
}