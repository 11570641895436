section {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(12, 12, 12, 0.85);

  /* center overlay text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  font-size: 2.4vw !important;
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.title {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  text-align: center;
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 800 !important;
}
.disclaimer {
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.5em;
}
.text {
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-size: 1.05em !important;
  font-weight: 500;
  line-height: 1.5em;
}
.container {
  margin: 30px;
}
.privacy_Policy {
  /* font-family: "Montserrat", sans-serif !important; */
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.3em !important;
  margin-left: 0;
  text-align: left;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .container {
    margin: 20px;
  }

  .privacy_Policy {
    font-size: 1.2em !important;
  }

  .text {
    font-size: 1em !important;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 20px;
  }
}

@media screen and (max-width: 576px) {
  .overlay .text {
    font-size: 2.7vw !important;
  }

  .privacy_Policy {
    font-size: 1.1em !important;
  }

  .text {
    font-size: 0.9em !important;
    line-height: 1.5em;
  }
}
