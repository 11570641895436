.contentStyle {
  /* margin-top :64px; */
  text-align: center;
  height: 95%;
  /* line-height: 120px; */
  color: #000000;
  background-color: #ffffff;
}

.footerStyle {
  text-align: center;
  font-size: 10px;
  /* background-color: #152966; */
  background-color: #000;
}

.layoutStyle {
  min-height: 100vh;
  background-color: white;
}

.scrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.backto {
  width: 25px;
}
.scrollContainer a {
  position: fixed;
  bottom: 20px;
  z-index: 11;
  border: 1px solid;
  padding: 15px;
  border-radius: 50%;
  right: 20px;
  background-color: "#63005f";
}
