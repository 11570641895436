.aboutContent {
  background-color: #000000 !important;
  font-size: 10px;
}

.aboutTitle {
  text-transform: uppercase;
  color: #fff !important;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 3em !important;
  font-optical-sizing: auto;
}

.aboutTitleWrapper {
  padding: 2em 0;
}

.aboutDescriptionWrapper {
  padding: 3em 0;
  padding-bottom: 3.4em;
}

.aboutDescription {
  color: #fff !important;
  /* font-family: "Montserrat", sans-serif; */
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.7em;
  line-height: 1.4em;
  text-align: center !important;
}

@media only screen and (max-width: 1200px) {
  .aboutTitle {
    font-size: 3.6em !important;
  }

  .aboutDescription {
    font-size: 1.8em;
    line-height: 1.4em;
  }
}

@media only screen and (max-width: 768px) {
  .aboutTitleWrapper {
    padding: 1.6em 0;
  }

  .aboutDescriptionWrapper {
    padding: 3em 0;
    padding-bottom: 3.4em;
  }

  .aboutTitle {
    font-size: 3.1em !important;
  }

  .aboutDescription {
    font-size: 1.4em;
    line-height: 1.4em;
  }
}

@media only screen and (max-width: 576px) {
  .aboutTitleWrapper {
    padding: 1.4em 0;
  }

  .aboutDescriptionWrapper {
    padding: 2.8em 0;
    padding-bottom: 3.2em;
  }

  .aboutTitle {
    font-size: 2.9em !important;
  }

  .aboutDescription {
    font-size: 1.35em;
    line-height: 1.4em;
  }
}
