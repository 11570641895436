.navbarWrapper {
  font-size: 10px;
}

.headerStyle {
  display: flex;
  align-items: center;
  height: 10em;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #000;
  z-index: 10;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3) !important;
}
.drawertext {
  display: block;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  color: white;
}
.drawertext:hover {
  color: wheat;
}
.drawercolor {
  background-color: rgb(121, 188, 210);
}
.searchMenu {
  border-radius: 20px;
  border: 1px solid white;
  padding: 6.5px;
  background-color: rgb(255, 255, 255, 0.2);
}
.menu {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  min-width: 0;
  padding: 7px 18px;
  font-optical-sizing: auto;
  background-color: #000;
  font-size: 18px !important;
  font-weight: 300 !important;
  border-bottom: none;
}

.Avatar {
  border: 1px solid #187ba5;
  padding: 7px 20px;
  font-weight: 500;
  color: rgb(239, 233, 233) !important;
}

.normalPadding {
  padding: 7px 18px;
  font-weight: 400;
}
.normalPadding:hover {
  background-color: #052c50;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5em;
}

.searchInput {
  border: none !important;
  border-bottom: 1px solid black;
}
.searchinput::placeholder {
  color: lightgray;
}
@media only screen and (max-width: 1200px) {
  .menu {
    font-size: 14px !important;
    padding: 7px 16px;
  }

  .Avatar {
    padding: 7px 16px;
  }

  .normalPadding {
    padding: 7px 16px;
  }

  .searchMenu {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1002px) {
  .menu {
    font-size: 14px !important;
    padding: 7px 16px;
  }

  .Avatar {
    padding: 7px 14px;
  }

  .normalPadding {
    padding: 7px 14px;
  }

  .searchMenu {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 905px) {
  .headerStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
  }

  .menu {
    font-size: 16px !important;
  }

  .search {
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }
}
