section {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(12, 12, 12, 0.85);

  /* center overlay text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
}
.formInput {
  text-align: left;
  padding: 8px 15px;
  border: none;
  border: 3px solid #00569e;
}

.overlay .text {
  font-size: 32px !important;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500;
  color: #fff;
  text-align: center;
  width: 75%;
}

.title {
  font-size: 40px !important;
  color: #fff !important;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 700 !important;
  margin-bottom: 0;
}

.text {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-size: 1.05em !important;
  font-weight: 500;
  line-height: 1.5em;
}

.container {
  margin: 30px;
}

.privacy_Policy {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.3em !important;
  margin-left: 0;
  text-align: left;
  width: 100%;
}

.card {
  border: 4px solid #00569e;
  border-radius: 8px;
}

.cardWrapper {
  padding: 30px;
}

.headingWrapper {
  background-color: #00569e;
  text-align: center;
}

.currencyWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
}

.currencyIcon,
.currency {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 48px;
}

.checklistWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 42px;
}

.checkIcon,
.checkText {
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
}

.heading {
  text-transform: uppercase;
  color: #fff;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 32px !important;
  padding: 20px 0;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 25px;
}

.buttonWrapper button {
  width: 220px;
  font-size: 18px;
  padding: 12px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  background-color: #00569e;
  border: none;
  border-radius: 8px;
}

@media screen and (max-width: 1200px) {
  .container {
    margin: 20px;
  }

  .privacy_Policy {
    font-size: 1.2em !important;
  }

  .title {
    font-size: 37px !important;
  }

  .overlay .text {
    font-size: 29px !important;
    line-height: 1.5em;
  }

  .currencyIcon,
  .currency {
    font-size: 44px;
  }

  .checkIcon,
  .checkText {
    font-size: 16px;
  }

  .heading {
    font-size: 29px !important;
  }

  .buttonWrapper button {
    width: 200px;
    font-size: 16px;
    padding: 10px 0;
    border-radius: 8px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin: 20px;
  }

  .privacy_Policy {
    font-size: 1.2em !important;
  }

  .title {
    font-size: 36px !important;
  }

  .overlay .text {
    font-size: 26px !important;
    line-height: 1.5em;
  }

  .currencyIcon,
  .currency {
    font-size: 41px;
  }

  .checkIcon,
  .checkText {
    font-size: 15px;
  }

  .heading {
    font-size: 27px !important;
  }

  .buttonWrapper button {
    width: 190px;
    font-size: 15px;
    padding: 10px 0;
    border-radius: 8px;
  }
}

@media screen and (max-width: 830px) {
  .container {
    margin: 20px;
  }

  .privacy_Policy {
    font-size: 1.2em !important;
  }

  .title {
    font-size: 36px !important;
  }

  .overlay .text {
    font-size: 26px !important;
    line-height: 1.5em;
  }

  .currencyIcon,
  .currency {
    font-size: 41px;
  }

  .checkIcon,
  .checkText {
    font-size: 12.5px;
    font-weight: 700;
  }

  .heading {
    font-size: 26px !important;
  }

  .buttonWrapper button {
    width: 180px;
    font-size: 13.5px;
    padding: 10px 0;
    border-radius: 8px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 20px;
  }

  .title {
    font-size: 34px !important;
  }

  .overlay .text {
    font-size: 22px !important;
    line-height: 1.5em;
  }

  .currencyIcon,
  .currency {
    font-weight: 600;
    font-size: 48px;
  }

  .checkIcon,
  .checkText {
    font-size: 18px;
  }

  .heading {
    font-size: 32px !important;
  }

  .buttonWrapper button {
    width: 220px;
    font-size: 18px;
    padding: 12px 0;
  }
}

@media screen and (max-width: 650px) {
  .container {
    margin: 20px;
  }

  .title {
    font-size: 33px !important;
  }

  .overlay .text {
    font-size: 20px !important;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 27px !important;
  }

  .overlay .text {
    font-size: 15px !important;
    line-height: 1.4em;
  }
}

@media screen and (max-width: 425px) {
  .title {
    font-size: 23px !important;
  }

  .overlay .text {
    font-size: 12px !important;
    line-height: 1.4em;
  }
}
