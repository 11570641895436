.title{
    font-weight: 600;
    margin-bottom: 5px !important;
}
.card{
    border-bottom: 1px solid #eae8e8;
    padding: 10px;
}
.title:hover{
    color: #005293;
}
/* @media screen and (max-width:992px) {
 .image{
    min-width: 160px !important;
 }   
} */